import React from 'react'
import IcSearchError from '@interco/icons/core/action-navigation/ic_search_error'
import IcClose from '@interco/icons/core/action-navigation/ic_close'

// Hooks
import useDataLayer from '../../../../../hooks/useDataLayer/dataLayerBody'

import * as S from '../../style'

interface IModalNotFoundProps {
  modal: boolean;
  handleClose: () => void;
}

const ModalNotFound = ({ modal, handleClose }: IModalNotFoundProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <>
      {modal ? <S.NotFoundModal className='pt-5 d-flex justify-content-center'>
        <div className='col-10 d-flex flex-column align-items-center pb-5 modal-content'>
          <div className='d-flex justify-content-end w-100'>
            <IcClose onClick={handleClose} height={24} width={24} color='#FF7A00' />
          </div>
          <div className='bg-white rounded-50 px-3 py-3'><IcSearchError height={32} width={32} color='#6A6C72' /></div>
          <p className='fs-18 lh-18 fs-lg-20 text-center text-grayscale-500 fw-700 mt-2 mb-3'>
            Não encontrou a vaga que procurava?
          </p>
          <p className='fs-16 lh-19 text-center mt-md-3 fw-500 text-grayscale-500'>Cadastre seu currículo em nosso banco de talentos!</p>
          <S.BtnOrange
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_2',
                element_action: 'click button',
                element_name: 'Cadastre seu currículo',
                section_name: '',
                redirect_url: 'https://boards.greenhouse.io/inter/jobs/4097248005?gh_jid=4097248005',
              })
            }}
            to='https://boards.greenhouse.io/inter/jobs/4097248005?gh_jid=4097248005'
            className='fs-14 rounded-8 px-4 py-2 text-center fw-700 mb-0'
          >
            Cadastre seu currículo
          </S.BtnOrange>
        </div>
      </S.NotFoundModal> : null}
    </>
  )
}

export default ModalNotFound
