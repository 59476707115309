const CustomerCentric = {
  variant1: {
    text: '#0277BD',
    color: '#0277BD',
    background: '#FFFFFF',
    hover: {
      color: '#025C92',
      background: '#E1F5FE',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#039BE5',
    hover: {
      background: '#0277BD',
    },
  },
  variant3: {
    text: '#084B6B',
    background: '#B3E5FC',
    hover: {
      background: '#4FC3F7',
    },
  },
  variant4: {
    text: '#084B6B',
    background: '#E1F5FE',
    hover: {
      background: '#B3E5FC',
    },
  },
}
const Sale = {
  variant1: {
    text: '#33691E',
    color: '#7CB342',
    background: '#FFFFFF',
    hover: {
      color: '#679537',
      background: '#F1F8E9',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#7CB342',
    hover: {
      background: '#33691E',
    },
  },
  variant3: {
    text: '#33691E',
    background: '#AED581',
    hover: {
      background: '#7CB342',
    },
  },
  variant4: {
    text: '#33691E',
    background: '#F1F8E9',
    hover: {
      background: '#DCEDC8',
    },
  },
}
const Communication = {
  variant1: {
    text: '#5D4037',
    color: '#5D4037',
    background: '#FFFFFF',
    hover: {
      color: '#432E28',
      background: '#EFEBE9',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#A1887F',
    hover: {
      background: '#5D4037',
    },
  },
  variant3: {
    text: '#5D4037',
    background: '#BCAAA4',
    hover: {
      background: '#A1887F',
    },
  },
  variant4: {
    text: '#3F1909',
    background: '#EFEBE9',
    hover: {
      background: '#D7CCC8',
    },
  },
}
const People = {
  variant1: {
    text: '#8C6911',
    color: '#DEAF3B',
    background: '#FFFFFF',
    hover: {
      color: '#8C6911',
      background: '#F0E6CC',
    },
  },
  variant2: {
    text: '#5D4200',
    background: '#F6C858',
    hover: {
      background: '#F4BB2F',
    },
  },
  variant3: {
    text: '#5D4200',
    background: '#F6DA94',
    hover: {
      background: '#F3CC6D',
    },
  },
  variant4: {
    text: '#5D4200',
    background: '#F0E6CC',
    hover: {
      background: '#E7D6AC',
    },
  },
}
const Technology = {
  variant1: {
    text: '#C2185B',
    color: '#C2185B',
    background: '#FFFFFF',
    hover: {
      color: '#9F144B',
      background: '#FCE4EC',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#EC407A',
    hover: {
      background: '#C2185B',
    },
  },
  variant3: {
    text: '#4E001F',
    background: '#F48FB1',
    hover: {
      background: '#EC407A',
    },
  },
  variant4: {
    text: '#C2185B',
    background: '#FCE4EC',
    hover: {
      background: '#F8BBD0',
    },
  },
}
const Regulatory = {
  variant1: {
    text: '#D32F2F',
    color: '#D32F2F',
    background: '#FFFFFF',
    hover: {
      color: '#B52626',
      background: '#FFEBEE',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#EF5350',
    hover: {
      background: '#D32F2F',
    },
  },
  variant3: {
    text: '#810707',
    background: '#EF9A9A',
    hover: {
      background: '#EF5350',
    },
  },
  variant4: {
    text: '#D32F2F',
    background: '#FFEBEE',
    hover: {
      background: '#FFCDD2',
    },
  },
}
const Operations = {
  variant1: {
    text: '#00796B',
    color: '#00796B',
    background: '#FFFFFF',
    hover: {
      color: '#005248',
      background: '#DDF0EF',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#26A69A',
    hover: {
      background: '#00796B',
    },
  },
  variant3: {
    text: '#00796B',
    background: '#B2DFDB',
    hover: {
      background: '#80CBC4',
    },
  },
  variant4: {
    text: '#00796B',
    background: '#DDF0EF',
    hover: {
      background: '#B2DFDB',
    },
  },
}
const ProductBusiness = {
  variant1: {
    text: '#303F9F',
    color: '#303F9F',
    background: '#FFFFFF',
    hover: {
      color: '#273381',
      background: '#E8EAF6',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#7986CB',
    hover: {
      background: '#303F9F',
    },
  },
  variant3: {
    text: '#303F9F',
    background: '#C5CAE9',
    hover: {
      background: '#9FA8DA',
    },
  },
  variant4: {
    text: '#303F9F',
    background: '#E8EAF6',
    hover: {
      background: '#C5CAE9',
    },
  },
}
const Data = {
  variant1: {
    text: '#B86224',
    color: '#D37430',
    background: '#FFFFFF',
    hover: {
      color: '#B56226',
      background: '#F8E2D3',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#D37430',
    hover: {
      background: '#B56226',
    },
  },
  variant3: {
    text: '#7A3B0D',
    background: '#E6BDA1',
    hover: {
      background: '#DEA882',
    },
  },
  variant4: {
    text: '#7A3B0D',
    background: '#F8E2D3',
    hover: {
      background: '#F2CCB0',
    },
  },
}
const FraudAML = {
  variant1: {
    text: '#02374F',
    color: '#02374F',
    background: '#FFFFFF',
    hover: {
      color: '#011C28',
      background: '#D1DCE1',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#1D4F65',
    hover: {
      background: '#133443',
    },
  },
  variant3: {
    text: '#FFFFFF',
    background: '#5A8294',
    hover: {
      background: '#4B6D7C',
    },
  },
  variant4: {
    text: '#052A3B',
    background: '#D1DCE1',
    hover: {
      background: '#B8C9D0',
    },
  },
}
const Finance = {
  variant1: {
    text: '#512DA8',
    color: '#512DA8',
    background: '#FFFFFF',
    hover: {
      color: '#422589',
      background: '#EDE7F6',
    },
  },
  variant2: {
    text: '#FFFFFF',
    background: '#9760E2',
    hover: {
      background: '#803DDB',
    },
  },
  variant3: {
    text: '#2E0E7D',
    background: '#D1C4E9',
    hover: {
      background: '#B39DDB',
    },
  },
  variant4: {
    text: '#512DA8',
    background: '#EDE7F6',
    hover: {
      background: '#D1C4E9',
    },
  },
}

export const themes = {
  customercentric: CustomerCentric,
  sale: Sale,
  communication: Communication,
  people: People,
  technology: Technology,
  regulatory: Regulatory,
  operations: Operations,
  productbusiness: ProductBusiness,
  data: Data,
  fraudaml: FraudAML,
  finance: Finance,
}
