import React, { useState, useEffect } from 'react'

// Hooks
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

import { formatArea } from '../../../utils/format'
import { themes } from '../../../styles/theme'

import IcChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { gray, grayscale } from '../../../styles/colors'

// shared
import removeAcento from '../../../shared/removeAcentos'

// Hooks
import useWidth from '../../../hooks/window/useWidth'

// Components
import LoadingIndicatorComponent from '../../../components/LoadingIndicator/index'
import { Tag } from '../../../components/Tag/index'
import NotFoundModal from './NotFound/NotFoundModal/_index'
import NotFoundMessage from './NotFound/NotFoundMessage/_index'

import { pageProps, apiData, categoriesProps } from './types'

import * as S from './style'

const WIDTH_MD = 768

const JobList = ({ searchText, jobsLocation, area, JobsContent, selectedAreas, selectedCargos, handleClose, modal }: pageProps) => {
  const [ categories, setCategories ] = useState<string[]>([])
  const [ sendDatalayerEvent ] = useDataLayer()
  const windowWidth = useWidth(100)

  const notFound = windowWidth >= WIDTH_MD ? <NotFoundMessage /> : <NotFoundModal modal={modal} handleClose={handleClose} />

  useEffect(() => {
    if (JobsContent.length > 0) {
      const listCategories = JobsContent.map((item: apiData) =>
        item.metadata
          .filter((item: categoriesProps) => item.name && removeAcento(item.name).toLowerCase() === 'area')
          .reduce((arr: categoriesProps, item: categoriesProps) => arr.concat(item)),
      )
      const categoriesName = listCategories && listCategories
        .filter((item: categoriesProps) => item.name && removeAcento(item.name).toLowerCase() === 'area')
        .map((item: categoriesProps) => item.value ? item.value[0] : 'Candidate Pool')
        .filter((item: string | null) => item !== null)
      const categoryList = categoriesName && categoriesName
        .filter((item: string, index: number) => categoriesName.indexOf(item) === index)
      setCategories(categoryList)
    }
  }, [ searchText, jobsLocation, area, JobsContent ])

  if (JobsContent?.length > 0) {
    if (searchText) {
      const lowerSearchText = searchText.toLowerCase()
      JobsContent = JobsContent?.filter(
        (item: apiData) => item.title.toLowerCase().indexOf(lowerSearchText) > -1 || item.location.name.toLowerCase().indexOf(lowerSearchText) > -1 ||
        (Array.isArray(item.metadata[1].value) && item.metadata[1].value.some((value: string) =>
          typeof value === 'string' && value.toLowerCase().indexOf(lowerSearchText) > -1))
      )
    }

    if (jobsLocation) {
      JobsContent = JobsContent.filter((item: apiData) => {
        return jobsLocation.includes(item.location.name.toLowerCase())
      })
    }

    if (area) {
      JobsContent = JobsContent.filter((item: apiData) => {
        const category = item.metadata.find((item: categoriesProps) => item.name && removeAcento(item.name).toLowerCase() === 'area')
        return category && category.value && category.value[0] === area
      })
    }

    if (selectedAreas.length > 0) {
      JobsContent = JobsContent.filter((item: apiData) => {
        const category = item.metadata.find((meta: categoriesProps) => meta.name && removeAcento(meta.name).toLowerCase() === 'area')
        return category && category.value && selectedAreas.includes(category.value[0])
      })
    }

    if (selectedCargos.length > 0) {
      JobsContent = JobsContent.filter((item: apiData) => {
        return selectedCargos.includes(item.title)
      })
    }

    if (JobsContent?.length === 0) {
      return notFound
    }
  }

  const getJobColor = (area: string) => {
    const formattedArea = formatArea(area).toLowerCase()
    return themes[formattedArea as keyof typeof themes]?.variant4.background || gray[400]
  }

  return (
    <>
      {(!JobsContent || JobsContent.length < 1) ? <div className='d-flex justify-content-center'><LoadingIndicatorComponent /></div>
        : <S.JobContent className='mb-5'>
          <div className='row d-flex justify-content-between'>
            <div className='col-12'>
              {
                JobsContent.map((obj: apiData) => {
                  return obj.metadata.filter((meta: categoriesProps) => meta.name && removeAcento(meta.name).toLowerCase() === 'area')
                    .filter((area: categoriesProps) => area.value ? area.value[0] === (area.value ? area.value[0] : 'Candidate Pool') : 'Candidate Pool').map((job: categoriesProps) => (
                      <a
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_2',
                            element_action: 'click button',
                            element_name: (obj.title.includes('DEV IOS') && obj.title.replace('DEV IOS', 'Dev IOS')) || obj.title.toLowerCase().replace('iii', 'III').replace('ii', 'II'),
                            section_name: '',
                            redirect_url: obj.absolute_url,
                          })
                        }}
                        className='link'
                        href={obj.absolute_url}
                        title={obj.title}
                        key={obj.id}
                      >
                        <S.CardJob>
                          <div className='d-flex align-items-center mb-3'>
                            {job.value && (
                              <Tag size='small' color={getJobColor(job.value[0])} colorText={grayscale[500]}>
                                {job.value[0]}
                              </Tag>
                            )}
                          </div>
                          <div className='d-flex justify-content-between'>
                            <h4 className='fs-18 lh-20 text-grascale--500 fw-600 title mb-0'>
                              {(obj.title.includes('DEV IOS') && obj.title.replace('DEV IOS', 'Dev IOS')) || obj.title.toLowerCase().replace('iii', 'III').replace('ii', 'II')}
                            </h4>
                            <div>
                              <IcChevronRight height={24} width={24} color='#FF7A00' />
                            </div>
                          </div>
                        </S.CardJob>
                      </a>
                    ))
                })
              }
            </div>
          </div>
        </S.JobContent>
      }
    </>
  )
}

export default JobList
