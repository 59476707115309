import styled from 'styled-components'
import { orange, white, grayscale } from '../../../styles/colors'
import breakpoints from '../../../styles/breakpoints'
import { Link } from 'gatsby'

export const NotFoundModal = styled.div`
  background-color: ${orange.default};
  border-radius: 12px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  p, span {
    font-family: 'Inter', Helvetica, sans-serif;
  }
`

export const NotFoundMessage = styled.div`
  p, span {
    font-family: 'Inter', Helvetica, sans-serif;
  }
  
  position: relative;
  top: 40px;
`

export const BtnOrange = styled(Link)`
  background-color: ${orange.extra};
  color: ${white};

  @media(min-width: ${breakpoints.md}) {
    width: 284px;
  }

  @media(min-width: ${breakpoints.lg}) {
    width: 392px;
  }
`

export const JobContent = styled.div`
  .category {
    font-size: 24px;
    line-height: 30px;
    
    @media(min-width: ${breakpoints.md}) {
      font-size: 20px;
      line-height: 25px;
    }
    @media(min-width: ${breakpoints.lg}) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .link {
    color: ${grayscale[500]} !important;
    display: block;
    
    & + a {
      margin-top: 16px;
    }
  }
`
export const CardJob = styled.div`
  padding: 16px;
  background-color: ${white};
  border-radius: 8px;
  border: 1px solid ${grayscale[100]};

  &:hover {
    border: 1px solid ${grayscale[300]};
  }

  .title {
    text-transform: capitalize;
  }

  .label {
    display: block;
    padding: 2px 4px;
    background: #F5F6FA;
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
    color: ${grayscale[400]};
    text-transform: capitalize;

    & + span {
      margin-left: 8px;
    }
  }
`
