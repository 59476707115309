export const formatArea = (area: string) => {
  if (!area) {
    return ''
  }
  const formattedArea = area.replace('&', '').replace(/\s/g, '')
  return formattedArea
}

export const normalizeString = (text: string) => {
  if (!text || text === null) {
    return ''
  }

  const words = text.split(/(\s+)/)

  let insideParentheses = false
  let insideBrackets = false

  const normalizedWords = words.map((word) => {
    if (word.trim() === '') return word

    if (word.includes('(')) insideParentheses = true
    if (word.includes('[')) insideBrackets = true

    if (['i', 'ii', 'iii', 'iv'].includes(word.toLowerCase())) {
      return word.toUpperCase()
    }

    if (insideParentheses || insideBrackets) {
      // Capitaliza cada palavra dentro de parênteses ou colchetes
      word = word.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
    } else {
      word = word.toLowerCase()
      word = word.charAt(0).toUpperCase() + word.slice(1)
    }

    if (word.includes(')')) insideParentheses = false
    if (word.includes(']')) insideBrackets = false

    return word
  })

  return normalizedWords.join('')
}
